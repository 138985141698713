import { slugify, dispatchVariableUpdateEvent, CSS_VALUE_FALSE } from ".";
import { hexToRgb } from "./utils";
const createVarConsumer = (meta, variableName, mappedValue, mapValue) => {
  const {
    element
  } = meta;
  const getter = function () {
    let consume = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
    let fallback = arguments.length > 1 ? arguments[1] : undefined;
    const consumable = `${variableName}${["number", "string"].indexOf(typeof consume) > -1 ? `-${consume}` : ""}`;
    const doConsume = ["boolean", "number", "string"].indexOf(typeof consume) > -1 && consume !== false;

    /*if (doConsume && !previousValues.has(consumable)) {
        console.log(consumable, new Error());
        // Currently, coverered through TypeScript
    }*/

    return doConsume ? `var(${consumable}${fallback ? `, ${fallback}` : ""})` : variableName;
  };
  const previousValues = new Map();
  iterateMappedValue(variableName, mappedValue, (variable, serializedValue, k) => {
    if (k !== undefined) {
      getter[k] = variable;
    }
    previousValues.set(variable, serializeValue);
  });
  getter.update = (value, css) => {
    let textContent = css || element.textContent;
    let changed = false;
    const mappedValue = executeMapValue(value, mapValue);
    iterateMappedValue(variableName, mappedValue, (k, v) => {
      const previousValue = previousValues.get(k);
      if (previousValue !== v) {
        previousValues.set(k, v);
        textContent = replaceInStyleSheet(textContent, k, v);
        changed = true;
      }
    });
    if (changed) {
      if (!css) {
        element.textContent = textContent;
      }
      meta.varsVal.set(variableName, value);
      dispatchVariableUpdateEvent(variableName, meta);
    }
    return textContent;
  };
  return getter;
};
const createVarFactory = (meta, extendDetached) => {
  const {
    className: mainClassName,
    isExtension,
    rules,
    id,
    element
  } = meta;

  // Make variables available to this class name
  const variableScopeClassName = isExtension && !extendDetached ? mainClassName.split("-ext")[0] : mainClassName;

  // Create the variables within this "scope", that means, when using `extendDetached` the variable names will be
  // the same accross multiple class names
  const variableScopeName = extendDetached ? id.split("-ext")[0] : id;

  // Make variables also available to pseudo elements as they are not inherited by default
  // See also https://stackoverflow.com/a/72294151/5506547
  /*variableScopeClassName += `,${["before", "after"]
      .map((pseudo) => `${variableScopeClassName} *::${pseudo}`)
      .join(",")}`;*/

  const createVariableName = suffix => `--${variableScopeName}-${meta.inc++}${suffix ? `-${suffix}` : ""}`;
  const createVar = (value, mapValue, suffix) => {
    const variableName = createVariableName(suffix);
    meta.varsVal.set(variableName, value);
    rules.set(variableScopeClassName, rules.get(variableScopeClassName) || {});
    const ruleSet = rules.get(variableScopeClassName);
    const mappedValue = executeMapValue(value, mapValue);
    iterateMappedValue(variableName, mappedValue, (k, v) => {
      ruleSet[k] = v;
    });
    return createVarConsumer(meta, variableName, mappedValue, mapValue);
  };
  const createVars = function (object, mapValue) {
    let createSuffix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    const consumers = {};
    for (const objectKey in object) {
      const value = object[objectKey];
      const mapFunction = mapValue?.[objectKey];
      consumers[objectKey] = createVar(value, mapFunction, createSuffix ? slugify(objectKey) : undefined);
    }
    return [consumers, newValue => {
      let {
        textContent
      } = element;
      for (const objectKey in newValue) {
        textContent = consumers[objectKey]?.update(newValue[objectKey], textContent);
      }
      if (textContent !== element.textContent) {
        element.textContent = textContent;
      }
      return textContent;
    }, newValue => {
      const result = {};
      const saveToResult = (k, v) => {
        if (k.endsWith("-not")) {
          throw new Error(`Boolish variable "${k}" cannot be created as style-attribute in your HTML tag as this is not supported by browsers. Alternatively, use a classname and pseudos to toggle styles.`);
        }
        result[k] = v === "" ? CSS_VALUE_FALSE : v;
      };
      for (const objectKey in newValue) {
        const consumer = consumers[objectKey];
        if (!consumer) {
          continue;
        }
        const variableName = consumer(false);
        const mapFunction = mapValue?.[objectKey];
        iterateMappedValue(variableName, executeMapValue(newValue[objectKey], mapFunction), saveToResult);
      }

      /*let str = objectToCss(result).trim();
      str = str
          .substr(3, str.length - 4)
          .trim()
          .replace(/^\s+/gm, "");*/

      return result;
    }];
  };
  return {
    varName: createVariableName,
    variable: createVar,
    vars: createVars
  };
};

/**
 * -----------------------------------------------------
 *
 * Misc helper functions for the variable factory.
 *
 * -----------------------------------------------------
 */

// See https://regex101.com/r/tQkPtN/1
const replaceInStyleSheet = (css, variableName, newValue) => {
  return css.replace(new RegExp(`^((?:    |      )${variableName}: )(.*)?;$`, "m"), `$1${newValue};`);
};
const executeMapValue = (value, fn) => {
  // Never touch a `--var` value
  if (typeof value === "string" && value.startsWith("var(--")) {
    return value;
  }
  return fn ? fn(value) : value;
};
const serializeValue = value => {
  if (typeof value === "boolean") {
    return value ? "initial" : "";
  }
  if (Array.isArray(value)) {
    return value.join(" ");
  }
  return value;
};
const iterateMappedValue = (variableName, value, callback) => {
  const keys = [];

  // Special case: boolean variables should be automatically be made available as `-not` variables
  // so this can be used with `boolNot`.
  const handleBooleans = (variableName, value) => {
    if (typeof value === "boolean") {
      callback(`${variableName}-not`, serializeValue(!value));
    }
  };

  // Proxy with value validation
  const useCallback = (variableName, value, key) => {
    // Avoid string literals exposing functions to the resulting property value.
    // Example: width: `calc(${thickness} + 1px)` -> width: calc(function () {
    if (typeof value === "string" && value.indexOf("function () {") > -1) {
      throw new Error(`${variableName} contains a serialized function ("${value}").`);
    }
    callback(variableName, value, key);
  };
  if (Array.isArray(value)) {
    useCallback(variableName, value.map(serializeValue).join(" "));

    // Also make the array values accessible as single property
    for (let i = 0; i < value.length; i++) {
      const arrVarName = `${variableName}-${i}`;
      handleBooleans(arrVarName, value[i]);
      useCallback(arrVarName, serializeValue(value[i]), i);
      keys.push(i);
    }
  } else if (typeof value === "object") {
    for (const objectKey in value) {
      const objVarName = `${variableName}-${slugify(objectKey)}`;
      handleBooleans(objVarName, value[objectKey]);
      useCallback(objVarName, serializeValue(value[objectKey]), objectKey);
      keys.push(objectKey);
    }
  } else {
    handleBooleans(variableName, value);
    useCallback(variableName, serializeValue(value));
  }

  // Reset keys which are no longer present in the mapped value (tbd when needed?!)
  /*if (previousKeys?.length) {
      let difference = previousKeys.filter((x) => !keys.includes(x));
      console.log(difference);
  }*/

  return keys;
};
const mapValueSuffix = suffix => value => `${value}${suffix}`;
const mapValueSuffixArray = suffix => value => value.map(v => `${v}${suffix}`);
const mapValueSuffixPx = mapValueSuffix("px");
const mapValueSuffixPxArray = mapValueSuffixArray("px");
const mapHex = hex => {
  const {
    r,
    g,
    b
  } = hexToRgb(hex);
  return {
    r,
    g,
    b,
    hex
  };
};
const mapStringToBoolean = (possibleValues, convertValues) => string => {
  return {
    ...convertValues.reduce((p, c) => {
      p[`is-${c.toLowerCase()}`] = string === c;
      return p;
    }, {}),
    ...mapStringToIsEmpty(false)(string)
  };
};
const mapStringArrayToBoolean = (possibleValues, convertValues) => array => {
  return {
    ...convertValues.reduce((p, c) => {
      p[`has-${c.toLowerCase()}`] = array.indexOf(c) > -1;
      return p;
    }, {})
  };
};
const mapStringToIsEmpty = function () {
  let encode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
  return string => {
    const length = string?.length;
    const val = string || "";
    return {
      "is-empty": !length,
      "is-filled": !!length,
      val: encode ? JSON.stringify(val) : val
    };
  };
};
const mapIsSet = string => {
  return {
    "is-set": typeof string !== "undefined"
  };
};
const mapBooleanToReverse = b => ({
  "is-true": b,
  "is-false": !b
});
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mapIgnore = _string => '"undefined"';
const mapAll = function (obj, fn) {
  return Object.keys(obj).reduce((c, p) => {
    c[p] = fn;
    return c;
  }, {});
};
export { createVarFactory, mapValueSuffix, mapValueSuffixArray, mapValueSuffixPx, mapValueSuffixPxArray, mapHex, mapStringToBoolean, mapStringArrayToBoolean, mapStringToIsEmpty, mapIsSet, mapBooleanToReverse, mapAll, mapIgnore };