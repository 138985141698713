import { useEffect, useMemo, useRef } from "react";
import { createPureScopedStylesheet } from "..";
const usePureScopedStylesheet = (callback, settings, updateFromObject) => {
  const result = useMemo(() => createPureScopedStylesheet(callback, settings), []);
  const {
    updater
  } = result;
  if (updateFromObject && updater) {
    for (const k in updater) {
      const isFirstRun = useRef(true);
      useEffect(() => {
        if (isFirstRun.current) {
          isFirstRun.current = false;
          return;
        }
        updater[k]?.(updateFromObject[k]);
      }, [updateFromObject[k]]);
    }
  }
  useEffect(() => {
    result.toggle(true);
    return () => !settings?.reuse && result.toggle(false);
  }, []);
  return result;
};
export { usePureScopedStylesheet };