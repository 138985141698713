import _extends from "@babel/runtime/helpers/extends";
import { Fragment } from "react";
import { extendTeachingStylesheet } from "@devowl-wp/web-cookie-banner";
import { useStylesheet } from "../../../contexts";
import { useBannerOrBlocker } from "../../../hooks";
const BodyDescription = _ref => {
  let {
    description,
    nl2br,
    teachings,
    isPostamble,
    children,
    ...rest
  } = _ref;
  const {
    individualPrivacyOpen
  } = useBannerOrBlocker();
  const {
    DescriptionContainer,
    Teaching,
    TeachingSeparator
  } = useStylesheet().extend(...extendTeachingStylesheet);
  return h(DescriptionContainer, {
    style: [true,
    // For content blockers, automatically use the "individual layout" text align
    undefined].indexOf(individualPrivacyOpen) > -1 ? undefined : {
      textAlign: "inherit"
    }
  }, children, !!description && h("p", _extends({
    role: "presentation"
  }, rest, {
    dangerouslySetInnerHTML: {
      __html: nl2br ? description.replace(/\n/gm, '<br aria-hidden="true" />') : description
    }
  })), teachings?.length > 0 && h(Fragment, null, (!!description || isPostamble) && h(TeachingSeparator, null), teachings.map(teaching => h(Teaching, {
    key: teaching,
    style: {
      marginBottom: isPostamble ? 7 : 0
    },
    dangerouslySetInnerHTML: {
      __html: teaching
    }
  }))));
};
export { BodyDescription };