import { useMemo } from "react";
import { useBanner, useStylesheet } from "../../contexts";
import { extendCommonContentStylesheet } from "@devowl-wp/web-cookie-banner";
const LanguageSwitcher = () => {
  const {
    FooterLanguageSwitcherSelect
  } = useStylesheet().extend(...extendCommonContentStylesheet);
  const {
    footerDesign: {
      languageSwitcher: languageSwitcherChoice
    },
    languageSwitcher,
    onLanguageSwitch
  } = useBanner();
  const current = useMemo(() => languageSwitcher.find(_ref => {
    let {
      current
    } = _ref;
    return current;
  }), [languageSwitcher]);
  const hasFlag = languageSwitcherChoice === "flags" && !!current?.flag;
  return h(FooterLanguageSwitcherSelect, {
    "data-flag": hasFlag
  }, hasFlag && h("span", {
    style: {
      backgroundImage: `url(${current.flag})`
    }
  }), h("select", {
    value: current?.locale,
    onChange: e => {
      onLanguageSwitch?.(languageSwitcher.find(_ref2 => {
        let {
          locale
        } = _ref2;
        return locale === e.target.value;
      }));
    }
  }, languageSwitcher.map(_ref3 => {
    let {
      locale,
      name
    } = _ref3;
    return h("option", {
      value: locale,
      key: locale
    }, name);
  })));
};
export { LanguageSwitcher };