import _extends from "@babel/runtime/helpers/extends";
import { Fragment } from "react";
import { PlainAntdIconAsSvg, useA11yId } from "../..";
import { CheckOutlined, MinusOutlined } from "@ant-design/icons-svg";
import { useStylesheet } from "../../contexts";
import { extendCommonStylesheet } from "@devowl-wp/web-cookie-banner";
const Checkbox = _ref => {
  let {
    hideCheckbox,
    isPartial,
    isChecked,
    isDisabled,
    fontSize,
    onToggle,
    children,
    after,
    ...rest
  } = _ref;
  const {
    checkbox: {
      className,
      style
    },
    Label
  } = useStylesheet().extend(...extendCommonStylesheet);
  const CheckboxIcon = isPartial ? MinusOutlined : CheckOutlined;
  const handleChange = e => {
    const {
      checked
    } = e.target;
    onToggle?.(checked);
  };
  const a11yId = useA11yId();
  return h(Label, null, !hideCheckbox && h(Fragment, null, h("input", {
    name: "checkbox[]",
    type: "checkbox",
    value: "1",
    checked: isChecked,
    disabled: isDisabled,
    className: className,
    style: style({
      fontSize
    }),
    onChange: handleChange,
    "aria-labelledby": a11yId
  }), h(PlainAntdIconAsSvg, _extends({
    "aria-hidden": true,
    icon: CheckboxIcon
  }, rest))), children && h("span", null, h("span", {
    id: a11yId,
    "aria-hidden": true
  }, children), after && h("span", {
    onClick: e => {
      // Do not modify the checkbox
      e.stopPropagation();
    }
  }, after)));
};
export { Checkbox };