import { prepareTcfString, resetConsentChangeHash } from "../..";
import { createTcfModel } from "@devowl-wp/cookie-consent-web-client";
const cancelEvent = e => {
  if (!e) {
    return;
  }
  e.preventDefault();

  // Some themes (e.g. AKEA) do have listeners on each link separately and do a custom redirect
  e.stopPropagation();
};
function useBannerStateContextCallbacks(_ref) {
  let {
    toggleOverlay,
    recorder,
    isTcf,
    defaultConsent,
    applyConsent
  } = _ref;
  const toggleWithActiveAction = (setContextValue, type) => setContextValue(state => {
    return {
      ...state,
      activeAction: type,
      individualPrivacyOpen: true,
      refreshSiteAfterSave: type === "change" ? 2000 : false,
      ...toggleOverlay(state, true, setContextValue)
    };
  });
  return {
    openHistory: function (setContextValue) {
      toggleWithActiveAction(setContextValue, "history");
      cancelEvent(arguments.length <= 1 ? undefined : arguments[1]);
    },
    openBanner: function (setContextValue) {
      toggleWithActiveAction(setContextValue, "change");
      cancelEvent(arguments.length <= 1 ? undefined : arguments[1]);
    },
    onSave: function (setContextValue) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }
      const [markAsDoNotTrack, buttonClicked] = args;
      setContextValue(state => {
        // TCF compatibility: persist TCF string
        let tcfString;
        if (process.env.IS_TCF === "1" && isTcf && state.tcf?.model) {
          tcfString = prepareTcfString(state.tcf.model, buttonClicked);
        }
        const appliedConsent = applyConsent({
          consent: state.consent,
          markAsDoNotTrack,
          buttonClicked,
          tcfString,
          recorderJsonString: recorder ? JSON.stringify(recorder.createReplay()) : undefined,
          uiView: state.activeAction === "change" ? "change" : state.activeAction !== "revoke" ? "initial" : undefined
        });
        if (state.refreshSiteAfterSave) {
          appliedConsent.then(() => {
            resetConsentChangeHash();

            // Fix iOS / Safari bug when we want to reload the page after a POST request
            setTimeout(() => window.location.reload(), state.refreshSiteAfterSave || /* BC */2000);
          });

          // When refreshing the site, do not show any out animation
          return state;
        } else {
          return {
            ...state,
            ...toggleOverlay(state, false, setContextValue)
          };
        }
      });
    },
    revokeConsent: function (setContextValue) {
      for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        args[_key2 - 1] = arguments[_key2];
      }
      const [successMessage, e] = args;
      setContextValue(state => {
        // Do not rely on current state of `groups` because it can be altered through history change
        const buttonClicked = "shortcode_revoke";
        let tcfString;
        if (process.env.IS_TCF === "1" && isTcf && state.tcf?.model) {
          tcfString = prepareTcfString(state.tcf.model, buttonClicked);
        }
        applyConsent({
          consent: defaultConsent,
          buttonClicked,
          tcfString
        }).then(() => {
          successMessage && alert(successMessage);
          resetConsentChangeHash();

          // Always reload (always wait 2s to ensure opt-out scripts are exeucted)
          // Fix iOS / Safari bug when we want to reload the page after a POST request
          setTimeout(() => window.location.reload(), 2000);
        });

        // Do not change anything
        return state;
      });
      cancelEvent(e);
    },
    updateTcfFilterBy: function (setContextValue) {
      for (var _len3 = arguments.length, args = new Array(_len3 > 1 ? _len3 - 1 : 0), _key3 = 1; _key3 < _len3; _key3++) {
        args[_key3 - 1] = arguments[_key3];
      }
      const [newFilter] = args;
      setContextValue(state => ({
        ...state,
        tcfFilterBy: newFilter
      }));
    },
    updateIndividualPrivacyOpen: function (setContextValue) {
      for (var _len4 = arguments.length, args = new Array(_len4 > 1 ? _len4 - 1 : 0), _key4 = 1; _key4 < _len4; _key4++) {
        args[_key4 - 1] = arguments[_key4];
      }
      const [opened] = args;
      setContextValue(state => ({
        ...state,
        individualPrivacyOpen: opened
      }));
    },
    updateGroupChecked: function (setContextValue) {
      for (var _len5 = arguments.length, args = new Array(_len5 > 1 ? _len5 - 1 : 0), _key5 = 1; _key5 < _len5; _key5++) {
        args[_key5 - 1] = arguments[_key5];
      }
      const [id, checked] = args;
      setContextValue(state => {
        const newConsent = JSON.parse(JSON.stringify(state.consent));
        if (checked) {
          newConsent.groups[id] = state.groups.filter(group => group.id === id)[0].items.map(_ref2 => {
            let {
              id
            } = _ref2;
            return id;
          });
        } else {
          delete newConsent.groups[id];
        }
        return {
          ...state,
          consent: newConsent,
          didGroupFirstChange: true
        };
      });
    },
    updateCookieChecked: function (setContextValue) {
      for (var _len6 = arguments.length, args = new Array(_len6 > 1 ? _len6 - 1 : 0), _key6 = 1; _key6 < _len6; _key6++) {
        args[_key6 - 1] = arguments[_key6];
      }
      const [groupId, id, checked] = args;
      setContextValue(state => {
        const newConsent = JSON.parse(JSON.stringify(state.consent));

        // Initially create "selected" cookies
        if (!newConsent.groups[groupId]) {
          newConsent.groups[groupId] = [];
        }
        const cookieIds = newConsent.groups[groupId];
        const indexOf = cookieIds.indexOf(id);
        if (checked && indexOf === -1) {
          cookieIds.push(id);
        } else if (!checked && indexOf > -1) {
          cookieIds.splice(indexOf, 1);
        }

        // Delete group entirely when no cookies selected
        if (!cookieIds.length) {
          delete newConsent.groups[groupId];
        }
        return {
          ...state,
          consent: newConsent
        };
      });
    },
    onClose: setContextValue => {
      setContextValue(state => {
        return {
          ...state,
          ...toggleOverlay(state, false, setContextValue),
          // This should be set always by you again as it is one-time
          refreshSiteAfterSave: false
        };
      });
    },
    selectHistoryEntry: function (setContextValue) {
      for (var _len7 = arguments.length, args = new Array(_len7 > 1 ? _len7 - 1 : 0), _key7 = 1; _key7 < _len7; _key7++) {
        args[_key7 - 1] = arguments[_key7];
      }
      const [groups, consentGroups, tcf] = args;
      setContextValue(state => {
        const additionalChanges = {
          // In case we previously used non-TCF functionality, do not show the TCF banner
          isTcf: !!tcf,
          tcf: null
        };
        if (process.env.IS_TCF === "1" && tcf) {
          Object.assign(additionalChanges, {
            tcf: "gvl" in tcf ? tcf : createTcfModel(tcf)
          });
        }
        return {
          ...state,
          groups,
          consent: {
            ...state.consent,
            groups: consentGroups
          },
          ...additionalChanges
        };
      });
    }
  };
}
export { useBannerStateContextCallbacks };