import { mapValueSuffixPx } from "@devowl-wp/web-scoped-css";
const extendBannerContentStylesheet = [Symbol("extendBannerContentStylesheet"), (_ref, _ref2) => {
  let {
    boolIf,
    boolSwitch,
    computed,
    boolNot,
    jsx
  } = _ref;
  let {
    dimsOverlay,
    dimsHeader: [, dimsHeaderVars],
    dimsFooter: [, dimsFooterVars],
    dimsRightSidebar: [, dimsRightSidebarVars],
    boolLargeOrMobile,
    isMobile,
    isBanner,
    design,
    bodyDesign,
    headerDesign,
    layout,
    decision,
    mobile,
    texts,
    activeAction,
    footerDesign,
    individualLayout,
    individualPrivacyOpen,
    footerBorderStyle,
    headerBorderStyle
  } = _ref2;
  const logoDimension = computed([headerDesign.logo, headerDesign.logoRetina, headerDesign.logoFitDim, headerDesign.logoRetinaFitDim, headerDesign.logoMaxHeight], _ref3 => {
    let [logo, logoRetina, logoFitDim, logoRetinaFitDim, logoMaxHeight] = _ref3;
    const isRetina = logoRetina && !logo?.endsWith(".svg") && window.devicePixelRatio > 1;
    const useFitDim = isRetina ? logoRetinaFitDim : logoFitDim;
    return useFitDim?.[0] > 0 ? {
      width: mapValueSuffixPx(useFitDim[0]),
      height: mapValueSuffixPx(useFitDim[1])
    } : {
      width: "auto",
      height: mapValueSuffixPx(logoMaxHeight)
    };
  });
  const headerFooterMaxWidth = boolIf({
    when: isBanner,
    then: {
      when: [individualPrivacyOpen, boolNot(individualLayout.inheritBannerMaxWidth)],
      then: individualLayout.bannerMaxWidth(),
      or: layout.bannerMaxWidth()
    }
  });
  const headerContainerBorderHeight = boolLargeOrMobile(headerDesign.borderWidth, boolIf);
  const [HeaderContainer] = jsx("div", {
    classNames: "header-container",
    position: "sticky",
    zIndex: 9,
    top: 0,
    background: boolIf(headerDesign.inheritBg, design.bg(), headerDesign.bg()),
    padding: boolLargeOrMobile(headerDesign.padding, boolIf),
    paddingBottom: `calc(${headerContainerBorderHeight} + ${boolLargeOrMobile(headerDesign.padding, boolIf, 2)})`,
    ...headerBorderStyle,
    pseudos: {
      ":has(>div:empty)": {
        display: "none"
      },
      ":has(>div:empty)+div": headerBorderStyle,
      ":after": {
        content: "''",
        display: "block",
        position: "absolute",
        left: "0px",
        right: "0px",
        bottom: "0px",
        background: headerDesign.borderColor(),
        height: headerContainerBorderHeight
      },
      ">div": {
        transition: "width 500ms, max-width 500ms",
        maxWidth: headerFooterMaxWidth,
        margin: "auto",
        display: "flex",
        alignItems: "center",
        position: "relative",
        textAlign: boolIf(headerDesign.inheritTextAlign, design.textAlign("val"), headerDesign.textAlign("val")),
        justifyContent: boolIf(headerDesign.inheritTextAlign, boolSwitch([[design.textAlign("is-center"), "center"], [design.textAlign("is-right"), "flex-end"]]), boolSwitch([[headerDesign.textAlign("is-center"), "center"], [headerDesign.textAlign("is-right"), "flex-end"]])),
        flexDirection: boolIf({
          when: [headerDesign.logo("is-filled"), texts.headline("is-filled")],
          then: boolSwitch([[headerDesign.logoPosition("is-left"), "row"], [headerDesign.logoPosition("is-right"), "row-reverse"]], "column")
        })
      },
      ">div>img": {
        margin: boolLargeOrMobile(headerDesign.logoMargin, boolIf),
        width: logoDimension.width(),
        height: logoDimension.height()
      }
    }
  });
  const closeIconDistance = boolSwitch([[[activeAction("is-filled"), decision.showCloseIcon()], "51px"]], "0px");
  const innerHeight = dimsOverlay[1].height();
  const [Content] = jsx("div", {
    classNames: "content",
    position: "relative",
    overflow: "auto",
    maxHeight: boolIf({
      when: isMobile,
      then: {
        when: individualPrivacyOpen,
        then: `calc(${innerHeight} - ${closeIconDistance})`,
        or: `calc(min(${innerHeight}, ${mobile.maxHeight()}) - ${closeIconDistance})`
      },
      or: `calc(${innerHeight} - ${boolIf(isBanner, "0px", "20px")} - ${closeIconDistance})`
    }),
    minHeight: boolIf(isMobile, `min(calc(${dimsHeaderVars.height()} + ${dimsFooterVars.height()} + ${dimsRightSidebarVars.height()} + 100px), ${innerHeight})`),
    ...(navigator.platform === "Win32" ? {
      overflow: CSS.supports("overflow", "overlay") ? "overlay" : "scroll",
      scrollbarWidth: "thin",
      scrollbarColor: `${bodyDesign.teachingsFontColor()} transparent`,
      pseudos: {
        // Create custom scrollbar when a border radius is active (https://css-tricks.com/the-current-state-of-styling-scrollbars/)
        "::-webkit-scrollbar": {
          width: "11px"
        },
        "::-webkit-scrollbar-track": {
          background: "transparent"
        },
        "::-webkit-scrollbar-thumb": {
          background: bodyDesign.teachingsFontColor(),
          borderRadius: layout.dialogBorderRadius(),
          border: `3px solid ${design.bg()}`
        }
      }
    } : {})
  });
  const footerContainerBorderHeight = boolLargeOrMobile(footerDesign.borderWidth, boolIf);
  const [FooterContainer] = jsx("div", {
    classNames: "footer-container",
    fontWeight: footerDesign.fontWeight(),
    color: footerDesign.fontColor(),
    position: "sticky",
    bottom: "0px",
    zIndex: 1,
    padding: boolLargeOrMobile(footerDesign.padding, boolIf),
    paddingTop: `calc(${footerContainerBorderHeight} + ${boolLargeOrMobile(footerDesign.padding, boolIf, 0)})`,
    background: boolIf(footerDesign.inheritBg, design.bg(), footerDesign.bg()),
    fontSize: boolLargeOrMobile(footerDesign.fontSize, boolIf),
    textAlign: boolIf(footerDesign.inheritTextAlign, design.textAlign("val"), footerDesign.textAlign()),
    ...footerBorderStyle,
    pseudos: {
      ":after": {
        content: "''",
        display: "block",
        position: "absolute",
        left: "0px",
        right: "0px",
        top: "0px",
        background: footerDesign.borderColor(),
        height: footerContainerBorderHeight
      },
      ">div": {
        transition: "width 500ms, max-width 500ms",
        maxWidth: headerFooterMaxWidth,
        margin: "auto",
        lineHeight: "1.8"
      },
      ":has(>div:empty)": {
        display: "none"
      }
    }
  });
  return {
    HeaderContainer,
    Content,
    FooterContainer
  };
}];
export { extendBannerContentStylesheet };