import { SCALING_WHEN_WINDOW_WIDTH_LOWER } from "@devowl-wp/web-cookie-banner";
import { useBanner, useBannerButtonCallbacks, CloseIcon } from "../../..";
const BannerCloseIcon = () => {
  const {
    headerDesign: {
      fontColor,
      fontSize
    },
    texts: {
      acceptEssentials
    },
    activeAction,
    pageRequestUuid4,
    i18n: {
      close,
      closeWithoutSaving
    },
    buttonClicked = ""
  } = useBanner();
  const {
    buttonClickedCloseIcon,
    closeIcon
  } = useBannerButtonCallbacks();
  const container = document.getElementById(pageRequestUuid4)?.querySelector("dialog");
  return h(CloseIcon, {
    width: fontSize,
    color: fontColor,
    tooltipText: activeAction ? activeAction === "change" ? closeWithoutSaving : close : acceptEssentials,
    tooltipAlways: container?.clientWidth < SCALING_WHEN_WINDOW_WIDTH_LOWER,
    framed: buttonClicked === buttonClickedCloseIcon,
    renderInContainer: container,
    onClick: closeIcon
  });
};
export { BannerCloseIcon };