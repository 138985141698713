/**
 * When using this in your app, please make sure to make the `JSX` namespaces available through
 * a package like `@types/react` to avoid the following error:
 *
 * > The inferred type of 'XXXXXXXXXXXXX' cannot be named without a reference to
 */

/**
 * When using this in your app, please make sure to make the `JSX` namespaces available through
 * a package like `@types/react` to avoid the following error:
 *
 * > The inferred type of 'XXXXXXXXXXXXX' cannot be named without a reference to
 */

const createJsxFactory = (_ref, _ref2) => {
  let {
    settings: {
      createElement,
      forwardRef
    }
  } = _ref;
  let {
    rule: ruleFn
  } = _ref2;
  const createJsx = (tag, propertiesOrRule, attributes) => {
    if (!createElement) {
      throw new Error("No createElement function passed.");
    }
    let ruleSelector;
    let ruleClass;
    if (Array.isArray(propertiesOrRule)) {
      ruleSelector = propertiesOrRule[0];
      ruleClass = propertiesOrRule[1];
    } else {
      const [createdRuleSelector, createdRuleClass] = ruleFn(propertiesOrRule);
      ruleSelector = createdRuleSelector;
      ruleClass = createdRuleClass;
    }
    const hasForwardRef = typeof forwardRef === "function";
    const jsxFn = (_ref3, ref) => {
      let {
        children,
        className,
        ...props
      } = _ref3;
      const useClassName = [ruleClass, className].filter(Boolean);
      const {
        modifyProps,
        ...restAttributes
      } = attributes || {};
      const useProps = {
        className: useClassName.join(" "),
        ...(hasForwardRef ? {
          ref
        } : {}),
        ...restAttributes,
        ...props
      };
      modifyProps?.(useProps);
      return createElement(tag, useProps, children);
    };
    return [hasForwardRef ? forwardRef(jsxFn) : jsxFn, ruleSelector, ruleClass];
  };
  const createJsxControl = (tag, _ref4, attributes) => {
    let [styled, rule, _, keys] = _ref4;
    const {
      modifyProps,
      ...restAttributes
    } = attributes || {};
    const [jsxFn] = createJsx(tag, [undefined, rule], {
      ...restAttributes,
      modifyProps: props => {
        props.style = {
          ...styled(props),
          ...(props.style || {})
        };

        // Remove customizable control properties from the rendered DOM
        const controlValues = {};
        for (const key of keys) {
          controlValues[key] = props[key];
          delete props[key];
        }
        modifyProps?.(props, controlValues);
      }
    });
    return jsxFn;
  };
  return {
    jsx: createJsx,
    jsxControl: createJsxControl
  };
};
export { createJsxFactory };