import { useRef, Fragment } from "react";
import { useRect } from "@reach/rect";
import { Portal, Tooltip } from ".";
import { useStylesheet } from "../contexts";
import { extendCommonContentStylesheet } from "@devowl-wp/web-cookie-banner";

/**
 * A portaled close icon creates a close icon rendered to a given container. This allows
 * you to put the close icon on the top right corner of an `overflow:hidden` container and
 * the tooltip gets rendered correctly.
 *
 * Attention: When using `renderInContainer` the close icon uses an expensive function to
 * observe the position within the container with https://reach.tech/rect/#userect-observe.
 * Only render the close icon when necessery!
 */
const CloseIcon = _ref => {
  let {
    width,
    color,
    tooltipText,
    framed,
    renderInContainer,
    tooltipAlways,
    onClick,
    thickness = 1
  } = _ref;
  const {
    closeIcon: {
      framed: framedClass,
      closeIcon,
      notPortalIcon,
      portalIcon,
      portalPlaceholder
    }
  } = useStylesheet().extend(...extendCommonContentStylesheet);
  const ref = useRef();
  const rect = useRect(ref, {
    observe: !!renderInContainer
  });
  const style = closeIcon({
    color,
    width,
    thickness,
    ...(rect?.y > 0 ? {
      rectX: rect.x,
      rectY: rect.y
    } : {})
  });
  const tooltip = h(Tooltip
  // Hide on screen readers as the dialog should be closed with "Escape"
  , {
    "aria-hidden": true,
    title: tooltipText,
    onClick: onClick,
    position: renderInContainer ? rect?.y > 50 ? "top-left" : "left" : "top-left",
    always: tooltipAlways,
    className: `${renderInContainer ? portalIcon : notPortalIcon} ${framed ? framedClass : ""}`,
    style: style
  }, h("span", {
    "aria-hidden": true
  }, h("span", null), h("span", null)));
  return renderInContainer ? h(Fragment, null, h("div", {
    className: portalPlaceholder,
    style: style,
    ref: ref
  }), rect?.y > 0 && h(Portal, {
    renderInContainer: renderInContainer
  }, tooltip)) : tooltip;
};
export { CloseIcon };