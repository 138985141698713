import { usePureScopedStylesheet } from "@devowl-wp/web-scoped-css";
import { createMainStylesheet, filterClassNameForCustomCss, updateCustomCssStyle } from "@devowl-wp/web-cookie-banner";
import { useBannerOrBlocker } from ".";
import { createElement, forwardRef, useEffect, useMemo } from "react";

/**
 * This hook creates the main stylesheet. Do not use this directly in all your components,
 * instead use an own context!
 */
function useMainStylesheetForContext() {
  const customize = useBannerOrBlocker();
  const {
    customCss: {
      css,
      antiAdBlocker
    }
  } = customize;
  const isWpCustomize = window.wp?.customize;
  const stylesheet = usePureScopedStylesheet(args => createMainStylesheet(customize, args), {
    reuse: "react-cookie-banner",
    createElement,
    forwardRef,
    filterClassName: function () {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      filterClassNameForCustomCss(stylesheet.className, antiAdBlocker === "n" || isWpCustomize, css, ...args);
    }
  }, customize);

  // Create unique symbol per rendered React root so this can be reused for "per-root"
  // extension for a stylesheet (e.g. own stylesheet for each visual blocker).
  const reactRootSymbol = useMemo(() => Symbol(stylesheet.functions.className()), []);
  stylesheet.specify(reactRootSymbol.description);

  // The hero content blocker is a React Portal in `<body` so we need another ID for specifity
  stylesheet.specify(`${reactRootSymbol.description}-o`);
  useEffect(() => {
    const {
      element,
      className: id
    } = stylesheet;
    updateCustomCssStyle(element, css, id);
  }, [css]);
  return {
    ...stylesheet,
    reactRootSymbol
  };
}
export { useMainStylesheetForContext };